import React from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Home from './components/Home'
import Receipt from './components/Receipt'
import Appbar from './components/Appbar'
import NodeHash from './components/NodeHash'

function App () {
  return (
    <Router>
      <Appbar />
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
		<Route path='/node/:hash'>
		  <NodeHash />
		</Route>
        <Route path='/:hash'>
          <Receipt />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
