import React, { useState, useEffect } from 'react'
import '../App.css'
import {
  useLocation,
  useHistory
} from 'react-router-dom'
import { getReceipt } from '../actions/index'
import SearchHash from './SearchHash'
import moment from 'moment'

import { Button, List, ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core'
import DnsIcon from '@material-ui/icons/Dns'
import StorageIcon from '@material-ui/icons/Storage'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import TimerIcon from '@material-ui/icons/Timer'
import ScheduleIcon from '@material-ui/icons/Schedule'
import RegistrationHashes from './RegistrationHashes'

const buttonStyle = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: 3,
  border: 0,
  color: 'white',
  height: 48,
  padding: '0 30px',
  marginTop: 50
}

const listLightItemStyle = {
  backgroundColor: '#454d5c'
}

const listDarkItemStyle = {
  backgroundColor: '#39404d'
}

const listHeaderStyle = {
  flex: 'none',
  width: '25%'
}

const listBodyStyle = {
  textAlign: 'left'
}

function Receipt (props) {
  const [err, setError] = useState('')
  const [receipt, setReceipt] = useState({})
  const [hash, setHash] = useState('')
  const [mounted, setMounted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [farmName, setFarmName] = useState('')
  const location = useLocation()
  const history = useHistory()

  // effect for when the routing changes
  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname === '/') return
      if (location.pathname.includes('/node')) return
      getReceiptFromHash(location.pathname.substring(1))
    })
  }, [history])

  // effect for when component mounts
  useEffect(() => {
    if (mounted) return
    getReceiptFromHash(location.pathname.substring(1))
  }, [location.pathname, hash, err, mounted])

  function getReceiptFromHash (hash) {
    setLoading(true)
    getReceipt(hash)
      .then(response => {
        const { receipt, farm_name } = response.data
        setMounted(true)
        setReceipt(receipt)
        setFarmName(farm_name)
        setHash(decodeURIComponent(hash))
        setError('')
        setLoading(false)
      })
      .catch(err => {
        setMounted(true)
        if (err.response) {
          if (err.response.status === 404) {
            setReceipt({})
            setError('Hash not found, try again with another hash!')
            setLoading(false)
          }
        }
      })
  }

  // If loading just show empty screen
  if (loading) {
    return (
      <div className='Receipt' />
    )
  }

  return (
    <div className='Receipt'>
      {err !== '' ? (
        <div>
          <p>{err}</p>
          <SearchHash setError={setError} />
        </div>
      ) : (
        <div>
          <Grid item xs={4} sm={8} md={12} style={{ margin: 'auto' }}>
            <p style={{ fontWeight: 'bold', marginBottom: 1 }}>Receipt for hash: </p>
            <p style={{ marginTop: 1 }}>{hash}</p>
            <div>
              <List>
                <ListItem style={listLightItemStyle}>
                  <ListItemIcon>
                    <DnsIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary='Node ID'
                    style={listHeaderStyle}
                  />
                  <ListItemText
                    style={listBodyStyle}
                    primary={receipt.node_id}
                  />
                </ListItem>
                <ListItem style={listDarkItemStyle}>
                  <ListItemIcon>
                    <StorageIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary='Farm ID'
                    style={listHeaderStyle}
                  />
                  <ListItemText
                    style={listBodyStyle}
                    primary={`${receipt.farm_id} - ${farmName}`}
                  />
                </ListItem>
                <ListItem style={listLightItemStyle}>
                  <ListItemIcon>
                    <ScheduleIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary='Period'
                    style={listHeaderStyle}
                  />
                  <ListItemText
                    style={listBodyStyle}
                    primary={`From ${moment.unix(receipt.period_start).format('YYYY-MM-DD hh:mm:ss')} to ${moment.unix(receipt.period_end).format('YYYY-MM-DD hh:mm:ss')}`}
                  />
                </ListItem>
                <ListItem style={listDarkItemStyle}>
                  <ListItemIcon>
                    <TimerIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary='Uptime in seconds'
                    style={listHeaderStyle}
                  />
                  <ListItemText
                    style={listBodyStyle}
                    primary={receipt.uptime_seconds}
                  />
                </ListItem>
                <ListItem style={listLightItemStyle}>
                  <ListItemIcon>
                    <MonetizationOnIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary='Payout amount'
                    style={listHeaderStyle}
                  />
                  <ListItemText
                    style={listBodyStyle}
                    primary={`${receipt.payout_amount / 10000000} TFT`}
                  />
                </ListItem>
                <ListItem style={listDarkItemStyle}>
                  <ListItemIcon>
                    <AccountBalanceWalletIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary='Payout address'
                    style={listHeaderStyle}
                  />
                  <ListItemText
                    style={listBodyStyle}
                    primary={receipt.payout_address}
                  />
                </ListItem>
              </List>
            </div>
            <RegistrationHashes registrationHashes={receipt.registration_hashes} />
            <Button
              style={buttonStyle}
              variant='outlined'
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(receipt)
              )}`}
              download='receipt.json'
            >
              Download
            </Button>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default Receipt
