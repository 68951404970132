import React, { useState } from 'react'
import '../App.css'
import { Input, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const buttonStyle = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: 3,
  border: 0,
  color: 'white',
  height: 48,
  padding: '0 30px',
  marginTop: 50
}

const inputStyle = {
  color: 'white',
  width: '80%'
}

function SearchHash (props) {
  const history = useHistory()
  const [hash, setHash] = useState('')

  const search = () => {
    if (hash === '') return
    if (props.setError) {
      props.setError('')
    }
    history.push(encodeURIComponent(hash))
  }

  return (
    <div>
      <Input
        autoFocus
        style={inputStyle}
        fullWidth
        value={hash}
        onChange={e => {
          setHash(e.target.value)
        }}
        color='secondary'
      />
      <br />
      <Button
        style={buttonStyle}
        variant='outlined'
        onClick={search}
      >
        Search
      </Button>
    </div>
  )
}

export default SearchHash
