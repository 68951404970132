import React from 'react'
import '../App.css'
import SearchHash from './SearchHash'
import MintingHashExplanation from './MintingHashExplanation'

function Home (props) {
  return (
    <div className='App'>
      <header className='App-header'>
        <div className='App-center'>
          <h2>Minting V2 Explorer</h2>
          <p style={{ fontSize: 20 }}>Enter your minting receipt hash</p>
          <br />
          <SearchHash />
        </div>
        <MintingHashExplanation />
      </header>
    </div>
  )
}

export default Home
