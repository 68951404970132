import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: 20,
      width: '30vw',
      backgroundColor: '#21252b'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    }
  })
)

export default function MintingHashExplanation () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Accordion style={{ backgroundColor: '#454d5c', color: 'white' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography className={classes.heading}>What is a minting hash?</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#39404d', margin: 'auto', padding: 20 }}>
          <p
            style={{ fontSize: 16, wordWrap: 'break-word', fontWeight: 'bold' }}
          >
            At the end of every Farming period (all equal in duration) the minting algorithm creates the amount of tokens due to the farmer (according to the farming rules <a href='https://wiki.threefold.io/#/farming_logic' target='blank'>here</a>) and records this token creation in a blockchain.  This record of token creation is identified by a “Minting hash”, a unique identifier for this token creation event.
            The email you receive at the end of each minting period will contain one minting hash per 3Node of your ThreeFold Farm.  Please use this minting hash to see the minting record information.
          </p>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
