import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#454d5c'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    }
  })
)

const listItemStyle = {
  borderBottom: '1px solid rgb(212, 212, 212)',
  width: '100%'
}

const listHeaderStyle = {
  flex: 'none',
  width: '25%',
  fontWeight: 'bold'
}

export default function RegistrationHashes (props) {
  const history = useHistory()
  const classes = useStyles()

  const routeToNodePage = (nodeHash) => {
    history.push(`node/${nodeHash}`)
  }

  if (!props.registrationHashes) return null

  return (
    <div className={classes.root}>
      <Accordion style={{ backgroundColor: '#454d5c', color: 'white' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography className={classes.heading}>Node's registration hashes</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <div>
            <div style={{ margin: 'auto', backgroundColor: '#39404d', padding: 20, fontSize: 16, fontWeight: 'bold' }}>
              <p>What is a "proof of capacity hash"?</p>
              <p>The ThreeFold proof of capacity algorithm checks everyday
              at random times which hardware is available in each and every 3Node on the TF Grid.
              </p>
              <p>This check results in a "proof of capacity" hash.</p>
              <p> This hash makes sure that all the hardware announced at the
              start of the farming effort remains online and available for
              cultivation (capacity renting).
              </p>
            </div>
            <List style={{ width: '100%' }}>
              {props.registrationHashes.map(hash => {
                return (
                  <ListItem key={hash} style={listItemStyle}>
                    <ListItemText
                      primary={
                        <a 
                          onClick={() => routeToNodePage(hash)} 
                          style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline' }}
                        >
                          {hash}
                        </a>
                      }
                      style={listHeaderStyle}
                    />
                  </ListItem>
                )
              })}
            </List>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
