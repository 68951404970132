import axios from 'axios'

const URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080'
export function getReceipt (hash) {
  return axios.get(`${URL}/api/v1/receipts/${hash}`)
}

export function getNode (hash) {
  return axios.get(`${URL}/api/v1/node_registrations/${hash}`)
}
