import React, { useState, useEffect } from 'react'
import {
  useLocation,
  useHistory
} from 'react-router-dom'
import { getNode} from '../actions/index'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'


function NodeHash() {
  const [node, setNode] = useState({})
  const [mounted, setMounted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [err, setError] = useState('')
  const location = useLocation()
  const history = useHistory()

  // effect for when component mounts
  useEffect(() => {
    if (mounted) return
      getNodeFromHash(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
  }, [location.pathname, node, err, mounted])

  function getNodeFromHash (hash) {
    setLoading(true)
    getNode(hash)
      .then(response => {
        const node = response.data
        setMounted(true)
        setNode(node)
        setError('')
        setLoading(false)
      })
      .catch(err => {
        setMounted(true)
        if (err.response) {
          if (err.response.status === 404) {
            setNode({})
            setError('Hash not found, try again with another hash!')
            setLoading(false)
          }
        }
      })
  }

  // If loading just show empty screen
  if (loading) {
    return (
      <div className='Node' />
    )
  }

  return (
	<JSONPretty data={node} className='Node' />	
  )
}

export default NodeHash 
